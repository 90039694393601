<template>
  <Form
    @submit="onSave"
    :validation-schema="{
      name: 'required',
      budget: 'decimal',
      budgetMetered: 'decimal',
      budgetIp: 'decimal',
      budgetHours: 'decimal',
      goalHours: 'decimal',
      categoryCapatazBudget: 'decimal',
      categoryOficialBudget: 'decimal',
      categoryOperarioBudget: 'decimal',
      categoryAyudanteBudget: 'decimal',
    }"
  >
    <div>
      <h4 class="mb-2 text-secondary">
        {{ action === "create" ? "Crear" : "Editar" }} subpartida
      </h4>
    </div>
    <div>
      <h4 class="mb-4">
        <span class="text-sm text-primary">Partida:</span>
        {{ departureSelected.name }}
      </h4>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Nombre</label>
        <Field v-model="name" name="name" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese nombre"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="name" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Código (Opcional)</label>
        <Field v-model="code" name="code" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese código"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="code" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>HH Presupuesto</label>
        <Field v-model="budgetHours" name="budgetHours" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese HH presupuesto"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="budgetHours" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>HH Meta</label>
        <Field v-model="goalHours" name="goalHours" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese HH meta"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="goalHours" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <Field v-model="startDate" name="startDate" v-slot="{ field }">
          <label>Fecha de Inicio</label>
          <input
            v-bind="field"
            type="date"
            min="1970-01-05"
            class="
              w-full
              relative
              py-3
              px-2
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="startDate" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <Field v-model="endDate" name="endDate" v-slot="{ field }">
          <label>Fecha de Fin</label>
          <input
            v-bind="field"
            type="date"
            min="1970-01-05"
            class="
              w-full
              relative
              py-3
              px-2
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="endDate" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Costo presupuesto (Opcional)</label>
        <Field v-model="budget" name="budget" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese costo presupuesto"
          />
        </Field>
      </div>
      <div class="flex pt-4">
        <div class="flex pb-2">
          <div class="flex items-center justify-center">
            <input
              v-model="calculateIp"
              type="checkbox"
              class="
                checked:bg-primary checked:border-transparent
                cursor-pointer
                ...
              "
            />
            <span class="select-none ml-2"> ¿Se calculará IP? </span>
          </div>
        </div>
      </div>
      <div v-if="calculateIp" class="my-2">
        <div class="mb-4">
          <div class="relative w-full flex flex-wrap items-stretch">
            <label class="text-dark font-semibold">Unidad</label>
            <Field v-model="unit" :value="unit" name="unit" v-slot="{ field }">
              <select
                v-bind="field"
                class="
                  relative
                  py-3
                  px-2
                  w-full
                  bg-white
                  rounded-sm
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                "
                placeholder="Seleccione"
              >
                <option value="m">m</option>
                <option value="m2">m2</option>
                <option value="m3">m3</option>
                <option value="und">und</option>
                <option value="puntos">puntos</option>
                <option value="HH">HH</option>
                <option value="kg">kg</option>
              </select>
            </Field>
          </div>
          <div>
            <ErrorMessage name="unit" v-slot="{ message }">
              <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
            </ErrorMessage>
          </div>
        </div>
        <div class="mb-4">
          <div class="relative w-full flex flex-wrap items-stretch">
            <label>Metrado presupuesto</label>
            <Field
              v-model="budgetMetered"
              name="budgetMetered"
              v-slot="{ field }"
            >
              <input
                v-bind="field"
                type="text"
                class="
                  relative
                  py-3
                  px-2
                  w-full
                  bg-white
                  rounded
                  shadow
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none focus:shadow-outline
                "
                placeholder="Ingrese metrado presupuesto"
              />
            </Field>
          </div>
          <div>
            <ErrorMessage name="budgetMetered" v-slot="{ message }">
              <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
            </ErrorMessage>
          </div>
        </div>
        <div class="mb-4">
          <div class="relative w-full flex flex-wrap items-stretch">
            <label>IP presupuesto</label>
            <Field v-model="budgetIp" name="budgetIp" v-slot="{ field }">
              <input
                v-bind="field"
                type="text"
                class="
                  relative
                  py-3
                  px-2
                  w-full
                  bg-white
                  rounded
                  shadow
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none focus:shadow-outline
                "
                placeholder="Ingrese IP presupuesto"
              />
            </Field>
          </div>
          <div>
            <ErrorMessage name="budgetIp" v-slot="{ message }">
              <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
            </ErrorMessage>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 pt-2">
      <h4 class="text-secondary text-sm">Cuadrilla</h4>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Presupuesto Capataz</label>
        <Field
          v-model="categoryCapatazBudget"
          name="categoryCapatazBudget"
          v-slot="{ field }"
        >
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese presupuesto capataz"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="categoryCapatazBudget" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Presupuesto Operario</label>
        <Field
          v-model="categoryOperarioBudget"
          name="categoryOperarioBudget"
          v-slot="{ field }"
        >
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese presupuesto operario"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="categoryOperarioBudget" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Presupuesto Oficial</label>
        <Field
          v-model="categoryOficialBudget"
          name="categoryOficialBudget"
          v-slot="{ field }"
        >
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese presupuesto oficial"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="categoryOficialBudget" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Presupuesto Ayudante</label>
        <Field
          v-model="categoryAyudanteBudget"
          name="categoryAyudanteBudget"
          v-slot="{ field }"
        >
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese presupuesto ayudante"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="categoryAyudanteBudget" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>

    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        class="
          rounded
          bg-primary
          py-2
          px-4
          text-white
          w-full
          flex
          justify-center
        "
        type="submit"
        :disabled="loadingSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { useField } from "vee-validate";
import { defineEmits, defineProps, ref } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  action: {
    type: String,
    default: "create",
  },
  departureSelected: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["onComplete"]);
const store = useStore();

const { value: name } = useField("name");
const { value: code } = useField("code");
const { value: budget } = useField("budget");
const { value: unit } = useField("unit");
const { value: budgetMetered } = useField("budgetMetered");
const { value: budgetIp } = useField("budgetIp");
const { value: budgetHours } = useField("budgetHours");
const { value: goalHours } = useField("goalHours");
const { value: startDate } = useField("startDate");
const { value: endDate } = useField("endDate");
const { value: categoryCapatazBudget } = useField("categoryCapatazBudget");
const { value: categoryOperarioBudget } = useField("categoryOperarioBudget");
const { value: categoryOficialBudget } = useField("categoryOficialBudget");
const { value: categoryAyudanteBudget } = useField("categoryAyudanteBudget");

let calculateIp = ref(false);
let responseType = ref(null);
let responseMessage = ref(null);
let loadingSave = ref(false);

const init = () => {
  if (props.action === "update") {
    name.value = props.departureSelected.name;
    code.value = props.departureSelected.code;
    budget.value = props.departureSelected.budget;
    calculateIp.value = props.departureSelected.isToCalculateIp;
    unit.value = props.departureSelected.unit;
    budgetMetered.value = props.departureSelected.budgetMetered;
    budgetIp.value = props.departureSelected.budgetIp;
    budgetHours.value = props.departureSelected.budgetHours;
    goalHours.value = props.departureSelected.goalHours;
    startDate.value = props.departureSelected.startDate;
    endDate.value = props.departureSelected.endDate;
    categoryCapatazBudget.value = props.departureSelected.categoryCapatazBudget;
    categoryOperarioBudget.value =
      props.departureSelected.categoryOperarioBudget;
    categoryOficialBudget.value = props.departureSelected.categoryOficialBudget;
    categoryAyudanteBudget.value =
      props.departureSelected.categoryAyudanteBudget;
  }
};

init();

const onSave = async (values, { resetForm }) => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    if (
      !isNaN(parseFloat(values.budget)) &&
      isFinite(values.budget) &&
      values.budget == 0
    )
      throw new Error("Costo presupuesto no puede ser menor a 0");

    if (calculateIp.value) {
      if (!values.unit) throw new Error("Seleccione la unidad");
      if (!values.budgetIp) throw new Error("IP presupuesto obligatorio");
      if (!values.budgetMetered)
        throw new Error("Metrado presupuesto obligatorio");

      values.isToCalculateIp = true;
    }

    if (
      calculateIp.value &&
      !isNaN(parseFloat(values.budgetMetered)) &&
      isFinite(values.budgetMetered) &&
      values.budgetMetered == 0
    )
      throw new Error("Metrado presupuesto no puede ser menor a 0");

    if (
      calculateIp.value &&
      !isNaN(parseFloat(values.budgetIp)) &&
      isFinite(values.budgetIp) &&
      values.budgetIp == 0
    )
      throw new Error("IP presupuesto no puede ser menor a 0");

    if (props.action === "create")
      await store.dispatch("createSubDeparture", {
        subDeparture: {
          ...values,
          parentDepartureKey: props.departureSelected.key,
        },
      });
    else
      await store.dispatch("updateSubDeparture", {
        subDeparture: {
          ...values,
          key: props.departureSelected.key,
        },
      });

    resetForm();
    emits("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al guardar la información";

    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>
