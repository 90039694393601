<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">Partidas</h2>
    <div class="flex">
      <button
        class="
          rounded
          border border-primary
          bg-white
          py-2
          px-4
          text-primary
          font-medium
          flex
          mr-3
        "
        @click="onOpenDrawerDeparture(true)"
      >
        Crear partida
      </button>
      <button
        class="
          rounded
          border border-primary
          bg-primary
          py-2
          px-4
          text-white
          font-medium
          flex
        "
        @click="drawerUploadDepartures = true"
      >
        Creación masiva
      </button>
    </div>
  </div>

  <div v-if="getDepartures">
    <div class="lg:p-10 flex align-start p-5">
      <div class="flex relative">
        <p class="m-auto pr-2">Filtro:</p>
        <input
          v-model.trim="search"
          class="
            relative
            p-1
            px-3
            lg:w-72
            bg-white
            rounded-sm
            border
            outline-none
            text-gray-700
            placeholder-gray-400
            pr-10
          "
          placeholder="Buscar partida"
        />
        <span class="absolute inset-y-0 right-0 flex items-center pl-2">
          <button
            type="submit"
            class="p-1 focus:outline-none focus:shadow-outline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              class="w-8 h-8"
            >
              <path
                d="M23.1022 22.1188L18.4647 17.4813C19.1844 16.5509 19.5737 15.4134 19.5737 14.217C19.5737 12.7849 19.0147 11.442 18.004 10.4295C16.9933 9.41701 15.6469 8.85986 14.2165 8.85986C12.7862 8.85986 11.4397 9.41879 10.429 10.4295C9.41652 11.4402 8.85938 12.7849 8.85938 14.217C8.85938 15.6474 9.4183 16.9938 10.429 18.0045C11.4397 19.017 12.7844 19.5741 14.2165 19.5741C15.4129 19.5741 16.5487 19.1849 17.479 18.467L22.1165 23.1027C22.1301 23.1163 22.1463 23.1271 22.164 23.1345C22.1818 23.1418 22.2009 23.1456 22.2201 23.1456C22.2393 23.1456 22.2584 23.1418 22.2761 23.1345C22.2939 23.1271 22.3101 23.1163 22.3237 23.1027L23.1022 22.3259C23.1158 22.3123 23.1266 22.2962 23.134 22.2784C23.1414 22.2606 23.1452 22.2416 23.1452 22.2224C23.1452 22.2031 23.1414 22.1841 23.134 22.1663C23.1266 22.1485 23.1158 22.1324 23.1022 22.1188ZM17.0451 17.0456C16.2879 17.8009 15.2844 18.217 14.2165 18.217C13.1487 18.217 12.1451 17.8009 11.3879 17.0456C10.6326 16.2884 10.2165 15.2849 10.2165 14.217C10.2165 13.1491 10.6326 12.1438 11.3879 11.3884C12.1451 10.6331 13.1487 10.217 14.2165 10.217C15.2844 10.217 16.2897 10.6313 17.0451 11.3884C17.8004 12.1456 18.2165 13.1491 18.2165 14.217C18.2165 15.2849 17.8004 16.2902 17.0451 17.0456Z"
                fill="#0057FF"
              />
            </svg>
          </button>
        </span>
      </div>
    </div>

    <div class="w-full lg:pr-10 pr-5 lg:pl-5 pl-0 relative overflow-x-hidden">
      <div class="relative overflow-x-hidden">
        <table class="w-full">
          <thead class="w-full">
            <tr class="flex">
              <th class="w-12"></th>
              <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
                <p>Nombre</p>
              </th>
              <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
                <p>Código</p>
              </th>
              <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
                <p>Costo presupuesto</p>
              </th>
              <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
                <p>Unidad</p>
              </th>
              <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
                <p>Metrado presupuesto</p>
              </th>
              <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
                <p>IP presupuesto</p>
              </th>
              <th class="px-6 py-2 text-sm text-gray-500 text-left w-80"></th>
            </tr>
          </thead>
          <tbody class="bg-white w-full">
            <template v-for="(departure, i) in matchDepartures" :key="i">
              <tr class="flex">
                <td class="px-4">
                  <button @click="toggleCollapse(departure)" class="pt-4">
                    <svg
                      :class="{ 'transform rotate-180': departure.isCollapsed }"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                </td>
                <td
                  class="text-left text-secondary font-light px-6 py-4 flex-1"
                >
                  <p class="text-sm">
                    {{ departure.name }}
                  </p>
                </td>
                <td
                  class="text-left text-secondary font-light px-6 py-4 flex-1"
                >
                  <p class="text-sm">
                    {{ departure.code }}
                  </p>
                </td>
                <td
                  class="text-left text-secondary font-light px-6 py-4 flex-1"
                >
                  <p class="text-sm">
                    {{ departure.budget }}
                  </p>
                </td>
                <td
                  class="text-left text-secondary font-light px-6 py-4 flex-1"
                >
                  <p class="text-sm">
                    {{ departure.unit }}
                  </p>
                </td>
                <td
                  class="text-left text-secondary font-light px-6 py-4 flex-1"
                >
                  <p class="text-sm">
                    {{ departure.budgetMetered }}
                  </p>
                </td>
                <td
                  class="text-left text-secondary font-light px-6 py-4 flex-1"
                >
                  <p class="text-sm">
                    {{ departure.budgetIp }}
                  </p>
                </td>
                <td class="px-6 py-4 w-80">
                  <div class="flex md:justify-end">
                    <a
                      class="
                        text-primary
                        font-bold
                        cursor-pointer
                        hover:bg-blue-50
                        px-2
                        mr-2
                      "
                      @click="
                        onOpenDrawerSubDeparture({
                          action: 'create',
                          departure: departure,
                        })
                      "
                    >
                      Agregar subpartida
                    </a>
                    <a
                      class="
                        text-primary
                        font-bold
                        cursor-pointer
                        hover:bg-blue-50
                        px-2
                        my-auto
                      "
                      @click="
                        onOpenDrawerDeparture({
                          action: 'update',
                          departure: departure,
                        })
                      "
                    >
                      Editar
                    </a>
                    <button
                      class="ml-4 hover:bg-red-50 px-2"
                      @click="onOpenModalDeleteDeparture(departure)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                          fill="#E00F57"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
              <!-- Collapsible Content Container -->
              <tr
                v-if="departure.isCollapsed"
                class="flex flex-col px-20 py-4 mb-2"
              >
                <td colspan="100%">
                  <div class="pb-5">
                    <h3 class="text-primary">Subpartidas</h3>
                  </div>
                  <div
                    v-if="
                      departure.subdepartures && departure.subdepartures.length
                    "
                  >
                    <table class="w-full">
                      <thead class="w-full">
                        <tr class="flex">
                          <th
                            class="
                              pr-5
                              py-2
                              text-sm text-gray-500 text-left
                              flex-1
                            "
                          >
                            <p>Nombre</p>
                          </th>
                          <th
                            class="
                              px-6
                              py-2
                              text-sm text-gray-500 text-left
                              flex-1
                            "
                          >
                            <p>Código</p>
                          </th>
                          <th
                            class="
                              px-6
                              py-2
                              text-sm text-gray-500 text-left
                              flex-1
                            "
                          >
                            <p>Costo presupuesto</p>
                          </th>
                          <th
                            class="
                              px-6
                              py-2
                              text-sm text-gray-500 text-left
                              flex-1
                            "
                          >
                            <p>Unidad</p>
                          </th>
                          <th
                            class="
                              px-6
                              py-2
                              text-sm text-gray-500 text-left
                              flex-1
                            "
                          >
                            <p>Metrado presupuesto</p>
                          </th>
                          <th
                            class="
                              px-6
                              py-2
                              text-sm text-gray-500 text-left
                              flex-1
                            "
                          >
                            <p>IP presupuesto</p>
                          </th>
                          <th
                            class="
                              px-6
                              py-2
                              text-sm text-gray-500 text-left
                              w-120
                            "
                          ></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white w-full">
                        <template
                          v-for="(subdeparture, i) in departure.subdepartures"
                          :key="i"
                        >
                          <tr class="flex">
                            <td
                              class="
                                text-left text-secondary
                                font-light
                                pr-5
                                py-4
                                flex-1
                              "
                            >
                              <p class="text-sm">
                                {{ subdeparture.name }}
                              </p>
                            </td>
                            <td
                              class="
                                text-left text-secondary
                                font-light
                                px-6
                                py-4
                                flex-1
                              "
                            >
                              <p class="text-sm">
                                {{ subdeparture.code }}
                              </p>
                            </td>
                            <td
                              class="
                                text-left text-secondary
                                font-light
                                px-6
                                py-4
                                flex-1
                              "
                            >
                              <p class="text-sm">
                                {{ subdeparture.budget }}
                              </p>
                            </td>
                            <td
                              class="
                                text-left text-secondary
                                font-light
                                px-6
                                py-4
                                flex-1
                              "
                            >
                              <p class="text-sm">
                                {{ subdeparture.unit }}
                              </p>
                            </td>
                            <td
                              class="
                                text-left text-secondary
                                font-light
                                px-6
                                py-4
                                flex-1
                              "
                            >
                              <p class="text-sm">
                                {{ subdeparture.budgetMetered }}
                              </p>
                            </td>
                            <td
                              class="
                                text-left text-secondary
                                font-light
                                px-6
                                py-4
                                flex-1
                              "
                            >
                              <p class="text-sm">
                                {{ subdeparture.budgetIp }}
                              </p>
                            </td>
                            <td class="py-4">
                              <a
                                class="
                                  text-primary
                                  font-bold
                                  cursor-pointer
                                  hover:bg-blue-50
                                  px-2
                                  my-auto
                                "
                                @click="
                                  onOpenDrawerSubDeparture({
                                    action: 'update',
                                    departure: subdeparture,
                                  })
                                "
                              >
                                Editar
                              </a>
                              <button
                                class="ml-4 hover:bg-red-50 px-2"
                                @click="
                                  onOpenModalDeleteDeparture(subdeparture)
                                "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                                    fill="#E00F57"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div v-else><p>No hay subpartidas</p></div>
                  <!-- Your collapsible content here -->
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else class="flex justify-center">
    <img :src="require('@/assets/loader.gif')" width="250" />
  </div>

  <Modal
    :open="modalDeleteDeparture"
    @update:open="modalDeleteDeparture = $event"
  >
    <template #body>
      <span v-if="departureSelected" class="font-medium">
        ¿Desea eliminar la
        {{ departureSelected.isSubDeparture ? "subpartida" : "partida" }} "{{
          departureSelected.name
        }}"?
      </span>
      <br />
    </template>
    <template #buttonDelete>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-red-600
          text-base
          font-medium
          text-white
          hover:bg-red-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          sm:ml-3 sm:w-auto sm:text-sm
        "
        :disabled="loadingDelete"
        @click="onDeleteDeparture"
      >
        <LoadingButtonIcon v-if="loadingDelete" />
        Eliminar
      </button>
    </template>
  </Modal>

  <EndDrawer :open="drawerDeparture" @update:open="drawerDeparture = $event">
    <DepartureForm
      v-if="drawerDeparture"
      :action="departureFormAction"
      :departure-selected="departureSelected"
      @onComplete="drawerDeparture = false"
    />
  </EndDrawer>
  <EndDrawer
    :open="drawerSubDeparture"
    @update:open="drawerSubDeparture = $event"
  >
    <SubDepartureForm
      v-if="drawerSubDeparture"
      :action="subDepartureFormAction"
      :departure-selected="departureSelected"
      @onComplete="drawerSubDeparture = false"
    />
  </EndDrawer>
  <EndDrawer
    :open="drawerUploadDepartures"
    @update:open="drawerUploadDepartures = $event"
  >
    <UploadDeparture />
  </EndDrawer>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import DepartureForm from "./forms/Departure";
import SubDepartureForm from "./forms/SubDeparture";
import UploadDeparture from "./forms/UploadDeparture";

const store = useStore();

const search = ref("");
const timerToSearch = ref(null);
const matchDepartures = ref([]);
const drawerDeparture = ref(false);
const drawerSubDeparture = ref(false);
const drawerUploadDepartures = ref(false);
const departureFormAction = ref("create");
const subDepartureFormAction = ref("create");
const departureSelected = ref(null);
const modalDeleteDeparture = ref(false);
const loadingDelete = ref(false);

const getDepartures = computed(() => store.getters.getDepartures);

watch(
  () => search.value,
  () => searchDepartures()
);

watch(
  () => getDepartures.value,
  () => {
    matchDepartures.value = [...getDepartures.value];
  },
  { deep: true }
);

const init = async () => {
  if (!getDepartures.value) await store.dispatch("getAllDepartures");

  matchDepartures.value = [...getDepartures.value];
};

init();

const searchDepartures = async () => {
  clearTimeout(timerToSearch.value);

  timerToSearch.value = setTimeout(async () => {
    matchDepartures.value = await store.dispatch("searchAllDepartures", {
      qSearch: search.value,
    });
  }, 400);
};

const toggleCollapse = (departure) => {
  departure.isCollapsed = !departure.isCollapsed;
};

const onOpenDrawerDeparture = ({ action, departure = null }) => {
  departureSelected.value = { ...departure };
  drawerDeparture.value = true;
  departureFormAction.value = action;
};

const onOpenDrawerSubDeparture = ({ action, departure = null }) => {
  departureSelected.value = { ...departure };
  drawerSubDeparture.value = true;
  subDepartureFormAction.value = action;
};

const onOpenModalDeleteDeparture = (departure) => {
  departureSelected.value = { ...departure };
  modalDeleteDeparture.value = true;
};

const onDeleteDeparture = async () => {
  try {
    loadingDelete.value = true;

    if (departureSelected.value.isSubDeparture) {
      await store.dispatch("deleteSubDeparture", {
        subDepartureKey: departureSelected.value.key,
        departureKey: departureSelected.value.parentDepartureKey,
      });
    } else {
      await store.dispatch("deleteDeparture", {
        departureKey: departureSelected.value.key,
      });
    }

    modalDeleteDeparture.value = false;
    departureSelected.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingDelete.value = false;
  }
};
</script>

<style scoped>
.w-120 {
  width: 120px;
}
</style>
